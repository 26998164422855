<template>
    <div class="display-flex row-wrap"  v-show="!isShowNoData && !isShowLoading && !isMobile" >
        <div class="match-detail__table-container">
            <p class="match-detail__table-title">Total</p>
            <div>
                <MatchTable :isShowTabs=false :type="'total'" :matchTableListData=matchTableList></MatchTable>
            </div>
        </div>
        <div class="match-detail__table-container">
            <p class="match-detail__table-title">Home</p>
            <div>
                <MatchTable :isShowTabs=false :type="'home'" :matchTableListData=matchTableList></MatchTable>
            </div>
        </div>
        <div class="match-detail__table-container">
            <p class="match-detail__table-title">Away</p>
            <div>
                <MatchTable :isShowTabs=false :type="'away'" :matchTableListData=matchTableList></MatchTable>
            </div>
        </div>
    </div>
    <div class="has-space"  v-show="isMobile">
        <div class="nav-pill__wrapper mb-main">
            <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tabs" :key="index" @click="selectTabs(o)">{{o.name}}</div>
        </div>
        <div v-show="currentTab === 'total'">
            <MatchTable :isShowTabs=false :type="'total'" :matchTableListData=matchTableList></MatchTable>
        </div>
        <div v-show="currentTab === 'home'">
            <MatchTable :isShowTabs=false :type="'home'" :matchTableListData=matchTableList></MatchTable>
        </div>
        <div v-show="currentTab === 'away'">
            <MatchTable :isShowTabs=false :type="'away'" :matchTableListData=matchTableList></MatchTable>
        </div>
    </div>
    <Loading v-show="isShowLoading"></Loading>
    <div class="no-data" v-show="isShowNoData">No Data</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import MatchTable from '@/components/match/football/Table.vue'

export default {
    components:{
        MatchTable
    },
    data() {
        return {
            isShowNoData :false,
            isShowLoading:false,
            matchTableList:[],
            matchId:'',
            tabs:[
                {
                    name:'All',
                    id:'home',
                    selected:true,
                },
                {
                    name:'Home',
                    id:'away',
                    selected:false,
                },
                {
                    name:'Away',
                    id:'away',
                    selected:false,
                },
            ],
            currentTab:'',
        }
    },
    computed: {
        ...mapGetters([
            'matchDetailScore',
            'matchId',
            'isMobile'
        ])
    },
    created() {
        this.matchId = this.$route.params.matchId

    },
    mounted() {     
        this.getSoccerLeagueRankingData();
        this.selectTabs(this.tabs[0])
    },
    methods: {
        ...mapActions([
            "getSoccerLeagueRanking",
            "getMatchDetailScoreAsync",
        ]),
         async getSoccerLeagueRankingData(){  
            this.isShowLoading = true;   

            let params = {
                matchId : this.matchId       
            } 
            const result = await this.getSoccerLeagueRanking(params);

            this.matchTableList = result.result;
            this.isShowLoading = false;

            if (this.matchTableList.length === 0) {
                this.isShowNoData = true;                
            } else {
                this.isShowNoData = false
            }                  
        }, 
        
        selectTabs(o) {
            this.tabs.forEach(x=>{
                x.selected = false
            });
            o.selected = true;
            this.currentTab = o.id;
        },          
    },
}
</script>

<style>
    .match-detail__table-container{
        margin-right: 2rem;
        flex:1;
    }
    .match-detail__table-title{
        font-size: .88rem;
        margin-bottom: .8125rem;
        padding-left: 1rem;
    }
</style>