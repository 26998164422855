<template>
    <div>
        <div v-if="isShowTabs" class="">
            <div class="nav-pill__wrapper mb-main">
                <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tabs" :key="index" @click="selectTabs(o)">{{o.name}}</div>
            </div>
        </div>
        
        <div class="match-table match-table__header" v-if="!isWorldCup && !isSpecialEvent">
            <div  class="display-flex">
                <div class="match-table__index">#</div>
                <div class="match-table__club">              
                    <div>{{$t('TEAM')}}</div>
                </div>
            </div>
            <div class="match-table__score">
                <div>MP</div>
                <div>W</div>
                <div>D</div>
                <div>L</div>
                <div>F</div>
                <div>A</div>
                <div>+-</div>
                <div>P</div>
            </div>   
        </div>
        <div v-show="!isLoading">    
            <template v-if="!isWorldCup && !isSpecialEvent">
                <Navigation :id="o.teamId" :category="'team'" :name="o.teamName"  class="match-table" v-for="(o, index) in matchTableList" :key="index" >
                    <div class="display-flex-center">
                        <div class="match-table__index"  :class="o.promotionColor">{{index +1}}</div>
                        <div class="match-table__club">                   
                            <div class="match-table__club-icon ">
                                <Icon  :logoUrl=o.logo ></Icon>
                                </div>
                            <div>{{o.teamName}}</div>
                        </div>
                    </div>
                    <div class="match-table__score">
                        <div>{{o.gamesPlayed === null? '-' : o.gamesPlayed}}</div>
                        <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                        <div>{{o.gamesDrawn === null? '-' : o.gamesDrawn}}</div>
                        <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                        <div>{{o.gamesScored === null? '-'  :o.gamesScored}}</div>
                        <div>{{o.gamesAgainst === null? '-' : o.gamesAgainst}}</div>
                        <div>{{o.goalsDifference === null? '-' : o.goalsDifference}}</div>
                        <div>{{o.points === null? '-' : o.points}}</div>
                    </div> 
                </Navigation>

                <div class="no-data" v-show="isShowNoData">{{$t("NO_TABLE")}}</div>
            </template>
            <template v-else>
                <template v-if="showTableHeader">
                    <div class="display-flex-align-center">
                        <div class="icon-league mr-05rem mb-05rem home-match__main-title-flag">
                            <img v-if="standingsPageleagueId === 75" :src="`${s3ImgUrl}/football/up/league/world_cup_2022_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 95" :src="`${s3ImgUrl}/football/up/league/afc_2024_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 93" :src="`${s3ImgUrl}/football/up/league/afcon_2024_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 67" :src="`${s3ImgUrl}/football/up/league/uefa_euro_2024.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 224" :src="`${s3ImgUrl}/football/up/league/copa_america_2024.png`" class="img-contain w-100 h-100" >
                        </div>
                        <div v-if="standingsPageleagueId === 75" class="match-table__main-group-stage-title"><span v-if="!isMobile">International | </span>FIFA World Cup 2022 &trade;<div class="home-match__country" v-if="isMobile">International</div></div>
                        <div v-else-if="standingsPageleagueId === 95" class="match-table__main-group-stage-title">{{ $t('AFC_ASIAN_CUP_2023') }}</div>
                        <div v-else-if="standingsPageleagueId === 93" class="match-table__main-group-stage-title">{{ $t('AFRICA_CUP_OF_NATIONS_2023') }}</div>
                        <div v-else-if="standingsPageleagueId === 67" class="match-table__main-group-stage-title">{{ $t('UEFA_EURO_2024') }}</div>
                        <div v-else-if="standingsPageleagueId === 224" class="match-table__main-group-stage-title">{{ $t('COPA_AMERICA_2024') }}</div>
                    </div>
                    <div class="match-table__main-group-stage-title">{{$t("GROUP_STAGE")}}</div>
                </template>
                
                <!-- YIM: special for World Cup 2022 -->
                <template v-if="isMobile">
                    <div class="display-flex-align-center">
                        <div class="icon-league mr-05rem mb-05rem home-match__main-title-flag">
                            <img v-if="standingsPageleagueId === 75" :src="`${s3ImgUrl}/football/up/league/world_cup_2022_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 95" :src="`${s3ImgUrl}/football/up/league/afc_2024_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 93" :src="`${s3ImgUrl}/football/up/league/afcon_2024_logo.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 67" :src="`${s3ImgUrl}/football/up/league/uefa_euro_2024.png`" class="img-contain w-100 h-100" >
                            <img v-else-if="standingsPageleagueId === 224" :src="`${s3ImgUrl}/football/up/league/copa_america_2024.png`" class="img-contain w-100 h-100" >
                        </div>
                        <!-- home-match__title-mobile -->
                        <div v-if="standingsPageleagueId === 75" class="match-table__main-group-stage-title">FIFA World Cup 2022 &trade;<div class="home-match__country">International</div></div>
                        <div v-else-if="standingsPageleagueId === 95" class="match-table__main-group-stage-title">{{ $t('AFC_ASIAN_CUP_2023') }}</div>
                        <div v-else-if="standingsPageleagueId === 93" class="match-table__main-group-stage-title">{{ $t('AFRICA_CUP_OF_NATIONS_2023') }}</div>
                        <div v-else-if="standingsPageleagueId === 67" class="display-flex flex-col mb-05rem">
                            <span class="match-table__main-group-stage-title mb-0">{{ $t('UEFA_EURO_2024') }}</span>
                            <span class="home-match__country" v-if="isMobile">Europe</span>
                        </div>
                        <div v-else-if="standingsPageleagueId === 224" class="display-flex flex-col mb-05rem">
                            <span class="match-table__main-group-stage-title mb-0">{{ $t('COPA_AMERICA_2024') }}</span>
                            <span class="home-match__country" v-if="isMobile">Americas</span>
                        </div>
                    </div>
                    <div class="match-table__main-group-stage-title">{{$t("GROUP_STAGE")}}</div>
                </template>

                <div class="match-table-wc__wrapper" v-for="(group, index) in matchTableModified" :key="index">
                    <!-- tableName -->
                    <div class="match-table-wc__header">{{group.displayGroupName.split("-")[1]}}</div>

                    <div class="match-table-wc__child-wrapper">
                        <div class="match-table match-table-row" >
                            <div  class="display-flex">
                                <!-- <div class="match-table__index">#</div> -->
                                <div class="match-table__club">              
                                    <div>{{$t('TEAM')}}</div>
                                </div>
                            </div>
                            <div class="match-table__score">
                                <div>MP</div>
                                <div>W</div>
                                <div>D</div>
                                <div>L</div>
                                <div>F</div>
                                <div>A</div>
                                <div>+-</div>
                                <div>P</div>
                            </div>   
                        </div>

                        <div class="match-table-row-wrapper">
                            <Navigation :id="o.teamId" :category="'team'" :name="o.teamName" v-for="(o, index) in group.total" :key="index">
                                <div class="match-table match-table-row">
                                    <div class="display-flex-center">
                                        <div class="match-table__index" :class="[isSpecialEvent && (index === 0 || index === 1) ? 'color-1' : (group.thirdQualified) ? 'color-2' : 'color-sp-1', o.promotionColor]">{{o.qualified}}</div>
                                        <div class="match-table__club">                   
                                            <div class="match-table__club-icon ">
                                                <Icon  :logoUrl=o.logo ></Icon>
                                                </div>
                                            <div>{{o.teamName}}</div>
                                        </div>
                                    </div>
                                    <div class="match-table__score">
                                        <div>{{o.gamesPlayed === null? '-' : o.gamesPlayed}}</div>
                                        <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                                        <div>{{o.gamesDrawn === null? '-' : o.gamesDrawn}}</div>
                                        <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                                        <div>{{o.gamesScored === null? '-'  :o.gamesScored}}</div>
                                        <div>{{o.gamesAgainst === null? '-' : o.gamesAgainst}}</div>
                                        <div>{{o.goalsDifference === null? '-' : o.goalsDifference}}</div>
                                        <div>{{o.points === null? '-' : o.points}}</div>
                                    </div> 
                                </div>
                            </Navigation>
                        </div>
                    </div>

                    <div class="no-data" v-show="isShowNoData">{{$t("NO_TABLE")}}</div>

                    <!-- <div class="match-table__tips-wrapper" v-for="(o, index) in promotionList" :key="index">
                        <div class="match-table__tips" >
                            <div class="match-table__index" :class="o.color">#</div>
                            <div>{{o.value}}</div>
                        </div>
                    </div> -->

                </div>
                <div class="match-table__tips-wrapper" v-show="itIsCopa()">
                    <div class="match-table__tips" >
                        <div class="match-table__index color-1">#</div>
                        <div>{{ $t('ADVANCE_TO_ROUND_OF_8') }} </div>
                    </div>
                </div>
                <div class="match-table__tips-wrapper" v-show="!itIsCopa()">
                    <div class="match-table__tips" >
                        <div class="match-table__index color-1">#</div>
                        <div>{{ $t('ADVANCE_TO_ROUND_OF_16') }} </div>
                    </div>
                </div>
                <div class="match-table__tips-wrapper" v-show="!itIsCopa()">
                    <div class="match-table__tips" >
                        <div class="match-table__index color-sp-1">#</div>
                        <div>{{ $t('POSSIBLE_QUALIFICATION_FOR_THE_NEXT_STAGE') }} </div>
                    </div>
                </div>
                <div class="match-table__tips-wrapper">
                    <div class="match-table__tips" >
                        <div class="match-table__index color-2">#</div>
                        <div>{{ $t('ADVANCE_TO_THE_KNOCKOUT_STAGE') }} </div>
                    </div>
                </div>
            </template>

            <div class="match-table__tips-wrapper" v-for="(o, index) in promotionList" :key="index">
                <div class="match-table__tips" >
                    <div class="match-table__index" :class="o.color">#</div>
                    <div>{{o.value}}</div>
                </div>
            </div>
        </div>
        <Loading v-show="isLoading"></Loading>

    </div>
  
</template>

<script>

import Loading from '@/components/indicator/Loading.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
        Loading
    },
    props: {
        matchTableListData: {
            type: Array
        },
        matchTableTipsList: {
            type: Array
        },
        isShowTabs: {
            type: Boolean,
            default:true
        },
        type: {
            type: String,
             default:'total'
        },
        params:{
            type: String,
        },
        leagueId:{
            type: String,
        },
        isWorldCup: {
            type: Boolean,
            default: false,
        },
        isSpecialEvent: {
            type: Boolean,
            default: false,
        },
        showTableHeader: {
            type: Boolean,
            default: false,
        },
    },
 	computed: {
			...mapGetters([
                'isMobile',
                'matchDetailScore',
                'currentLocale',
                'currentSportType'
			]),
            isShowTabs()  {
                
            },
            matchTableModified() {
                if (this.isWorldCup || this.isSpecialEvent) {
                    this.matchTableList.forEach(group => {
                        group.total.forEach((team, i) => {
                            if (this.isSpecialEvent && (i <= 2) && !this.itIsCopa()) {
                                team.qualified = "#";
                            } else {
                                team.qualified = (i == 0 || i == 1) ? "#" : "";
                            }
                        });
                    });

                    return this.matchTableList;
                }
            },
		},
    data() {
        return {
                s3ImgUrl:config.s3ImgUrl,
                isLoading:false,
                isShowNoData:false,
                tabs:[
                    {
                        name:'All',
                        id:'',
                        selected:true,
                    },
                    {
                        name:'Home',
                        id:'1',
                        selected:false,
                    },
                    {
                        name:'Away',
                        id:'2',
                        selected:false,
                    },
                ],
                matchTableList:[],    
                standingsPageleagueId: null,            
            }
    },

        watch:{
            params:{
                deep:true,
                handler(newVal,oldVal) {//pass param and get data from api here
                    this.getSoccerLeagueRankingData(newVal);
                }
            },
            matchTableListData:{// direct pass data to render
                handler(newVal,oldVal) {  
                    
                    this.matchTableList = (this.isWorldCup || this.isSpecialEvent) ? newVal : newVal[this.type];
                    
                    this.promotionList =  newVal['promotionList'];
                }
            },
            $route: {
                immediate: true,
                handler(to, from) {
                    this.standingsPageleagueId = parseInt(this.$route.params.leagueId);
                },
            }
        },

    mounted() {
        this.itIsCopa();
    },
   
    methods: {
        	...mapActions([
				"getSoccerLeagueRanking",
                "getMatchDetailScore",
                "getMatchDetailScoreAsync",
			]),

            itIsCopa() {
                return [224].includes(this.$route.params.leagueId * 1);
            },


            async getSoccerLeagueRankingData(leagueIdList){
          
                let params = {
                    leagueIdList : leagueIdList
                }
                this.isLoading = true;
              
                const result = await this.getSoccerLeagueRanking(params);
                this.matchTableList = result.result;

                if (this.matchTableList.length === 0) {
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                }

                this.isLoading = false;
            },

            prevBtnMenuLeagueTableListClick(){
                this.getSoccerLeagueRanking({
                    "leagueId":''
                }).then(res => {
                this.matchTableList =  res.result;
                }).catch(res => {
                    ;
              })
            },
            selectTabs(o) {
                this.tabs.forEach(x=>{
                    x.selected = false
                });
                o.selected = true;
                    this.isLoading=true;
                // this.matchObj=[];
                    setTimeout(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })       
                    }      
                    , 800);
            },
      
    }
}
</script>
<style scoped>
    .match-table__main-group-stage-title {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .match-table__header-country__wrapper{
            display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .88rem;
        margin-bottom: .4rem;
    }
    .match-table__header{        
        background-color: #212B30;
    }
    .match-table-wc__header {
        background-color: #212B30;
        font-weight: 700;
        font-size: 0.875rem;
        padding: 0.875rem 0.75rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    .match-table-wc__wrapper {
        margin-bottom: 1.5rem;
    }
    .match-table-wc__child-wrapper .match-table {
        border-bottom: 0;
        position: relative;
    }
    .match-table-wc__child-wrapper .match-table-row::after {
        content: '';
        width: calc(100% - 1.6rem);
        border-bottom: 1px solid #212B30;
        position: absolute;
        bottom: 0;
    }
    .match-table-wc__child-wrapper .match-table-row-wrapper a:last-of-type .match-table-row::after {
        border-bottom: none;
    }
    .match-table-wc__child-wrapper .match-table-row:hover::after{
        border-bottom: 1px solid var(--bg-hover);
    }
    .match-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:#CFD9DE;
        border-bottom:1px solid #212B30;
        padding: .5rem 0.8rem;
        font-size: .6875rem;
    }

    .match-table.selected{
        background-color: #212B30;
        color: white;
        font-weight: 700;
    }
    .match-table:hover{
      background-color: var(--bg-hover);  
    }
    .match-table__index{
        margin-right: .3rem;
        width: 1.1rem;
    }
    .color-1{
        color:#5CD66C;
    }
    .color-2{
        color:#2E8FEF;
    }
    .color-3{
        color:#f7eb83;
    }
    .color-4{
        color:#ff9800;
    }
    .color-5{
        color:#673ab7;
    }
    .color-p{
        color:#ffc0cb;
    }
    .color-r{
        color: #d81f0d;
    }
    .color-sp-1  {
        color: #ED9C5A;
    }
    .match-table__club{
        display:flex;
        align-items: center;
        flex:2;
        max-width: 8rem;
    }

    .match-table__club-icon{
        margin-right: .5rem;
    }
    .match-table__score{
        display:flex;
        width:10rem;
        justify-content: space-between;
        text-align: center;
    }
    .match-table__score>div{
        flex:1;
        margin: 0 .15rem;
        min-width: 1.01rem;
    }
    .match-table__tips-wrapper{
        background-color: var(--color-theme);
        color: #CFD9DE;
    }
    .match-table__tips{
        display: flex;
        flex-direction: row;
        margin: 0 .8rem;
        padding: .5rem 0;
        font-size: .7rem;
    }

    @media (max-width: 768px) {
        .match-table__main-group-stage-title {
            font-size: 0.875rem;
        }
    }
</style>
